/* App.css */

/* General styles */
.container {
  width: 70%;
  margin: 0 auto;
  text-align: center;
}

@media (max-width: 768px) {
  .container {
    width: 90%;
    padding: 10px;
  }
}

.about-me p {
  font-size: 16px;
  line-height: 28px;
  font-weight: 300;
  color: #333333;
}

.about-me {
  margin: 1.5em 0em;
}

.about_text {
  text-align: left;
  text-indent: 2ch;
  line-height: 1.5em;
}

.App {
  text-align: center;
}

@media (max-width: 768px) {
  h1, h2 {
    font-size: 1.5em;
  }
}

/* Responsive images */
img {
  max-width: 100%;
}

.App-logo {
  height: 40vmin;
  pointer-events: none;
}

.App-header {
  min-height: 100vh;
  display: flex;
  flex-direction: column;
  align-items: center;
  justify-content: center;
  font-size: calc(10px + 2vmin);
  color: black;
}

.App-link {
  color: #61dafb;
}

@keyframes App-logo-spin {
  from {
    transform: rotate(0deg);
  }
  to {
    transform: rotate(360deg);
  }
}



.cv-button {
  padding: 10px;
  border-radius: 0.25em;
  border: solid 1px #bbbbbb;
  margin: 1em;
  background-color: white;
}

.external-links {
  display: flex;
  flex-direction: row;
}

.external-link-button {
  display: flex;
}

.flex_figures {
  display: flex;
  width: 75%;
}

@media (max-width: 768px) {
  .flex_figures {
    width: 100%;
    flex-direction: column;
  }
}


.gallery-container {
  display: flex;
  flex-direction: column;
}

.gallery-row {
  display: flex;
  flex-direction: row;
  width: 100%;
  margin: 0;
}

.gallery-row img {
  margin: 0.2em;
  width: 33%;
}

@media (max-width: 768px) {
  body {
    overflow-x: hidden;
  }
  .gallery-row {
    flex-direction: column; /* Stack items vertically on smaller screens */
  }

  .gallery-row img {
    width: 100%; /* Full width for each image on smaller screens */
  }
}

@media (max-width: 768px) {
  .gallery-row img {
    width: 100%;
  }
}

h1 {
  font-family: 'NotoItalic';
  font-weight: 600;
}

h2 {
  font-size: 28px;
  font-family: 'NotoItalic';
}

h3 {
  font-size: 24px;
  font-family: 'ProximaNova';
}

hr {
  border-bottom: solid 1px black;
}

.line-divider {
  width: 80%;
}

.link {
  border: solid 1px #bbbbbb;
  padding: 0.33em 0.66em;
}

.link-icon {
  max-width: 28px;
  width: 28px;
  min-width: 28px !important;
  margin: 0px 18px 0px 0px !important;
}

#maker_gallery {
  padding-top: 2em;
  width: 90%;
  margin: 0 auto;
}

.maker_pic {
  width: 100%;
}

.navigation {
  margin-top: 2em;
}

.navigation a {
  padding: 1em;
  font-size: 16px;
  text-decoration: none;
  color: #333333;
}

.paper_authors,
.paper_affiliations {
  position: relative;
  text-align: center;
}

.about-text {
  text-align: center;
}

.normal_p {
  width: 100%;
  display: block;
  margin-block-start: 1em;
  margin-block-end: 1em;
  margin-inline-start: 0px;
  margin-inline-end: 0px;
  unicode-bidi: isolate;
  padding: 0em;
}

p {
  width: 80%;
  text-align: left;
  position: relative;
  margin: 0 auto;
  line-height: 1.5em;
  padding: 1em;
}

.paper_figure {
  width: 50%;
  position: relative;
  left: 50%;
  transform: translateX(-50%);
  margin: 0 auto;
}

.split-horizontal {
  display: flex;
  flex-direction: column;
}

@media (min-width: 768px) {
  .split-horizontal {
    flex-direction: row;
  }
}

.project-row img {
  margin: 1em;
  min-width: 196px;
}

.project-row {
  display: flex;
  flex-direction: row;
  background-color: white;
  border-radius: 0.25em;
  color: black;
  width: 100%;
  margin-top: 0.5em;
  text-align: left;
}

@media (max-width: 768px) {
  .project-row {
    flex-direction: column;
    padding: 0.5em;
    text-align: center !important;
  }
  .project-row h3 {

    text-align: center
  }
}

.project-page-link {
  margin: 0.25em;
  font-size: 16px !important;
  border-radius: 0.2em;
  color: black;
  border: solid 1px #bbbbbb;
  padding: 0.33em 0.66em;
}

.project-row a {
  text-decoration: none;
  margin: 0.25em;
  font-size: 16px !important;
  border-radius: 0.2em;
  color: black;
}

.project-row h3 {
  text-align: left;
}

.project-row p {
  font-size: 16px;
}

.project-preview {
  max-width: 196px;
}

.vertical-flex {
  display: flex;
  flex-direction: column;
  justify-content: center;
  align-items: center;
}