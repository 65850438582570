.blog p {
    font-size: 1.25em !important;
    line-height: 1.5em !important;
    text-align: left;

    text-indent: 1em;
    margin-top: 1em;

}

#blog_author {
    margin: 0 auto;
    text-align: center;
}

#blog_date {
    margin: 0 auto;
    text-align: center;
}

.excerpt {

    border-left: solid 1px black;
    padding: 0;
    margin: 0;
    margin-left: 10%;
    background-color: rgba(0,200,10,0.1);
    padding: 1em 0em;
    /* width: 80%; */


}

.codeblock {

    text-align: left  !important;

    padding: em !important;



}

.three_column {

}

.grid_pic {
    width: 100%;
    /* stroke: solid 2px black; */

}

.grid_gallery {
    padding-top: 1em;
    margin-top: 1em !important;
    background-color: #f9f9f9;
    border: solid 5px lightgray;
    position: relative;
    left: 0.5em;
    border-radius: 1em;

}


    
.blog {
    width: 70% !important ;
    margin: 0 auto;
}