
h3 {
    font-family: 'Proxima Nova';
    text-align: center;
}

body {
    font-size: 1em;
}


.alice-carousel {
    width: 90%; 
}


.grid-container {
    display: grid;
    grid-template-columns: repeat(auto-fit, minmax(196px, 1fr));
    gap: 16px;
    padding: 16px;
    width: 80%;
    margin: 0 auto;
  }
  
  .grid-item {
    display: flex;
    justify-content: center;
    align-items: center;
  }
  
  .grid-item img {
    max-width: 100%;
    height: auto;
  }


#logomotion_figure_gallery {
    width: 100%;
    margin-top:2em;
    background-color: white;
    border-top: solid 1px deeppink;
    padding:1em;
}

.logomotion_figure {
    border-radius:0.25em;
    padding: 2em;
    width:70% !important;

}

.two_column {
    display:flex
}


.left_half {
    width: 50%;

}
.right_half {

    width: 50%;
}

@media (max-width: 768px) {
    body {
        overflow-x: hidden;
    }
    .two_column {
      flex-direction: column; /* Change to column for smaller screens */
    }
  
    .left_half, .right_half {
      width: 100%; /* Full width for each column on smaller screens */
    }
  }

.three_column {
  display: flex;
  flex-direction: row; /* Default to row for larger screens */
}

.third1, .third2, .third3 {
  width: 29.5%; /* Default widths for larger screens */
}

.third3 {
  width: 41%; /* Specific width for the third column */
}

@media (max-width: 768px) {
  .three_column {
    flex-direction: column; /* Change to column for smaller screens */
  }

  .third1, .third2, .third3 {
    width: 100%; /* Full width for each column on smaller screens */
  }
}
.caption {
    text-align: justify;
    margin:0.5em;
}
.logomotion_header {
    text-align:left;
}
.right_column {
    padding:2em;

}
.logomotion_column_figure {
    border-radius:0.25em;
    padding: 2em;
    width: 100% !important;


}
.carouselContent {
    width: 512px;
}

.navigation {
    text-align: center;
    margin-top: 2em;
}

.navigation a {
padding: 1em;
font-size: 16px;
text-decoration: none;
color: #333333;

}

