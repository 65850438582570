.discoContainer {
    background-color:white;
    padding:20px;
}

h3 {
    font-family: 'Proxima Nova';
    text-align: center;
}


#discotitle {
    color: deeppink;
}
.alice-carousel {
    width: 100%; 
}

.pink {
    color: deeppink;
}

#disco_figure_gallery {
    width: 100%;
    margin-top:2em;
    background-color: white;
    border-top: solid 1px deeppink;
    padding:1em;
}

.disco_figure {
    border-radius:0.25em;
    padding: 2em;

}

.alice-carousel__stage-item {
    margin: 0.5em !important;
}

.navigation {
    text-align: center;
    margin-top: 2em;
}

.navigation a {
padding: 1em;
font-size: 16px;
text-decoration: none;
color: #333333;

}

.videoCard {

    border-radius: 0.5em;
    background-color: rgb(255,164,191);
    padding:0.5em;


}

.songName {
    color: deeppink;
    text-align: left;
}

.artistName {
    color: white;
    font-weight: 700px;
    font-size: 18px;
}

.videoCard video {

    border-radius: 0.25em;
    width: 90%;

    
}
video {
    max-width: 248px !important;
    border-radius: 0.25em;

    
}

video::-webkit-media-controls-panel {
    background-image: linear-gradient(transparent, transparent) !important;
}