
@font-face {
  font-family: 'ProximaNova';
  src: local('ProximaNova'), url("../public/fonts/Proxima_Nova_Regular.ttf") format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}



@font-face {
  font-family: 'DMSerif';
  src: local('DMSerif'), url("../public/fonts/DMSerifDisplay-Italic.ttf") format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}


@font-face {
  font-family: 'NotoItalic';
  src: local('NotoItalic'), url("../public/fonts/NotoItalic.ttf") format('truetype');
  /* other formats include: 'woff2', 'truetype, 'opentype',
                            'embedded-opentype', and 'svg' */
}

body {
  margin: 0;
  font-family:  "ProximaNova", system-ui, sans-serif,
    sans-serif;
  -webkit-font-smoothing: antialiased;
  -moz-osx-font-smoothing: grayscale;
  background-color: white;


}



code {
  font-family: source-code-pro, Menlo, Monaco, Consolas, 'Courier New',
    monospace;
}

.code-block {
  white-space: pre-wrap; /* Ensures lines wrap */
  word-wrap: break-word; /* Ensures long words wrap */
  text-align:left;
}


.video_title {
  font-family: "ProximaNova";
  font-size: 5em !important;
  font-weight:500;
  text-align:center;


}

.video_authors {
  font-size: 2.24em;
  line-height:1em;

}
sup {
  padding:0.25em;
}

.video_title_card_container {
  width: 90%;
  margin: 0 auto;
  /* margin: 1em; */
}
.empty {
  height: 100px;;
  width: 1px;
}